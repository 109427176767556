import Invalid from '../assets/Invalid.jpg'
import Title from '../assets/MasterLogo.png';

const InvalidSerial = (props) => {


    return (
        <div className='productauthenticator'>     
            <div className='titleBanner'>
                <div>
                    <img src={Title} alt="" />
                </div>
            </div>        
            <div className="serialchecker">
                <div className='serialcheckerhead'>
                    PRODUCT AUTHENTICATOR<br/>产品检测
                </div>
                <div className="serialcheckerbody">

                </div>
                <div className='contents'>
                    <div className='leftImagePanel'>
                        <img src={Invalid} alt="" />
                    </div>
                    <div className='rightImageTextHeader'>
                        <div className='rightImageTextHeaderTitle'>
                            INVALID SERIAL NUMBER
                        </div>
                        <p style={{color:'red'}}>You entered: {props.serialNumber.toUpperCase()}</p>
                        <p>
                            您输入的序号为：{props.serialNumber.toUpperCase()} 
                        </p> 
                        <p>
                            未知的序号
                        </p>
                        <p>
                            This serial number has not been recognized. It is possible that this is not a genuine SmoothSkin product. 此序号无法识别。此产品可能不是smoothskin慕金的正品。
                        </p>
                        <p>
                            Please contact the retailer you purchased it from. 请联系您购买此产品的零售商。
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <button className='backButton' onClick={() => props.isHidden()}>←&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GO BACK</button>
                        </p>                        
                    </div>                   
                </div>  

            </div>
        </div>
    )
}

export default InvalidSerial;