import React, {useState, useEffect} from 'react';
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';

import SerialBox from '../assets/Serial Authenticator_Box_CHText.jpg';
import SerialLoc from '../assets/Serial Authenticator_Battery_v03.jpg';
import Title from '../assets/MasterLogo.png';

const serialCheckURL = 'https://cisdata.azurewebsites.net/api/DataWareHouse/AuthenticateSerial/';

const ProductAuthenticator = (props) => { 

    const [submitted, setSubmitted] = useState('');
    const [serialValue, setSerialValue] = useState('');
    const [open, setOpen] = React.useState(false);
    const [list, setList] = useState();
    const [isLoaded, setIsLoaded] = useState(true);
    const handleChange = (event) => {
        setSerialValue(event.target.value);
    }

    const handleClick = () => {
        //props.isHidden();

        if(serialValue)
        {
            props.passSerial(serialValue);   
            props.passValidSerial('true');    
            setSubmitted(serialValue);
            setIsLoaded(false);
            setSerialValue('');
        }
        else{
            props.passSerial(serialValue);
            props.passValidSerial('false');
        }   setSerialValue('');
    };


    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    useEffect(() => {
        props.passList(list);

        (async () => {
            if (!isLoaded) {
                await fetch(serialCheckURL + submitted, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(response => response.json()
                ).then(data => {
                        setList(data);
                        setIsLoaded(true);
                    }
                ).catch(err => {
                    throw new Error(err)
                });          
            } 
        })(list)

        if(typeof list!=='undefined')
        {
            props.isHidden();
        }
    }, [isLoaded]);



    return (
        <div className='productauthenticator'>  
            <div className='titleBanner'>
                <div>
                    <img src={Title} alt="" />
                </div>
            </div>   
            <div className="serialchecker">
                <div className='serialcheckerhead'>
                    PRODUCT AUTHENTICATOR<br/>产品检测
                </div>
                <div className="serialcheckerbody">
                    <TextField
                        focused
                        label="请输入您的序列号" 
                        id="standard-search" 
                        variant='standard'
                        type='search'
                        inputProps={{maxLength: 13}}
                        autoComplete="off"
                        value={serialValue}
                        onChange={handleChange}
                        helperText={" "}
                    />
                    <Button 
                        id="searchButton"
                        variant="contained" 
                        disableElevation
                        size='large'
                        onClick={() => {handleClick()}}
                    >
                        查询我的产品&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;→
                    </Button>
                </div>
                <div className='contents'>
                    <div className='leftImagePanel'>
                        <img src={SerialBox} alt="" />
                        <img src={SerialLoc} alt="" />
                    </div>
                    <div className='rightImageTextHeader'>
                        <div className='rightImageTextHeaderTitle'>
                            CHECK YOUR 检测您的脱毛仪是否为正品 SMOOTHSKIN PRODUCT IS GENUINE
                        </div>
                        <p>
                            Use this tool to check that you have received a genuine SmoothSkin product. To do this, you’ll need your device’s serial number. You can find this in the product box beneath the QR code and on the product power supply unit beneath the QR code.
                        </p> 
                        <p>
                            使用此工具检测您所收到的smoothskin慕金脱毛仪是否是正品。做此检测只需要您脱毛仪的序号。序号在仪器外包装盒的二维码下方，或者是在仪器电源转换单元的二维码下方。
                        </p>
                        <p>
                            Enter the serial number into the box above and click “Check my product” to get started. 
                        </p>
                        <p>
                            请在上方输入您的脱毛仪的序号，然后点击 &quot;查询我的产品&quot; 开始检测。
                        </p>
                    </div>                 
                </div>  
            </div>
        </div>
    )
}

export default ProductAuthenticator;