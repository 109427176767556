
import React, {useState} from 'react';
import './App.css';
import ProductAuthenticator from './components/ProductAuthenticator';
import AuthenticationSuccess from './components/AuthenticationSuccess';
/*import ReAuthenticate from './components/ReAuthenticate';*/
import UnknownSerial from './components/UnknownSerial';
import InvalidSerial from './components/InvalidSerial';
/*import Blacklisted from './components/Blacklisted';*/
/*import Suspended from './components/Suspended';*/

function App() {


  const [startHidden, setStartHidden] = useState('false');
  const [authSuccessHidden, setAuthSuccessHidden] = useState('false');
  const [unknownHidden, setUnknownHidden] = useState('false');
  const [invalidHidden, setInvalidHidden] = useState('false');
  const [serialNumber, setSerialNumber] = useState('');
  const [imageData, setImageData] = useState('');
  const [validSerial, setValidSerial] = useState('');
  const [validSerialFormat, setValidSerialFormat] = useState('');
  const [dataReturned, setDataReturned] = useState('');

  const passSerial = (data) => {
    setSerialNumber(data);

    let regex = /^[a-zA-Z][a-zA-Z0-9]\d\d[a-zA-Z]\d\d\d\d\d\d\d\d$/i;

    if (regex.test(data))
    {
      setValidSerialFormat('true');
    }
    else{
      setValidSerialFormat('false');
    }
  };

    const passList = (data) => {
      if (typeof(data) != "undefined"){
        if(data.length > 0)
        {
          setDataReturned('true');
          setImageData(data[0].productImage);
        }
        else
        {
          setDataReturned('false');
        }        
      }
  }

  const passValidSerial = (data) => {
    setValidSerial(data);
  };

  return (
    <div>
      <div>
        {startHidden === 'false' && (<ProductAuthenticator passList={passList} passValidSerial={passValidSerial} passSerial={passSerial} isHidden={() => { setStartHidden('true'); setUnknownHidden('false'); setAuthSuccessHidden('false'); setInvalidHidden('false')}} />)}

        {startHidden==='true' && authSuccessHidden==='false' && validSerialFormat === 'true' && dataReturned === 'true' && serialNumber !== 'Invalid serial number' && <AuthenticationSuccess imageData={imageData} serialNumber={serialNumber} isHidden={() =>  { setAuthSuccessHidden('true'); setStartHidden('false');}} />}

        {startHidden==='true' && validSerialFormat === 'true' && dataReturned === 'false' && (<UnknownSerial serialNumber={serialNumber} isHidden={() => { setUnknownHidden('true'); setStartHidden('false')}} />)}

        {startHidden==='true' && invalidHidden==='false' && validSerialFormat === 'false' && <InvalidSerial serialNumber={serialNumber} isHidden={() => { setInvalidHidden('true'); setStartHidden('false')}} />}
      </div>
    </div>
  );
}

export default App;