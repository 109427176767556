import Success from '../assets/Success.jpg'
import Title from '../assets/MasterLogo.png';

const AuthenticationSuccess = (props) => {


    return (
        <div className='productauthenticator'>  
            <div className='titleBanner'>
                <div>
                    <img src={Title} alt="" />
                </div>
            </div>   
            <div className="serialchecker">
                <div className='serialcheckerhead'>
                    PRODUCT AUTHENTICATOR<br/>产品检测
                </div>
                <div className="serialcheckerbody">

                </div>
                <div className='contents'>
                    <div className='leftImagePanelSuccess'>
                        <img src={props.imageData} alt="" />
                    </div>
                    <div className='rightImageTextHeader'>
                        <div className='rightImageTextHeaderTitle'>
                            THIS IS A GENUINE SMOOTHSKIN PRODUCT<br/>此产品为正品。
                        </div>
                        <p>您输入的序号为: {props.serialNumber.toUpperCase()}</p>
                        <p style={{color:'green'}}>You entered: {props.serialNumber.toUpperCase()}</p>
                        <p>
                            This is a valid SmoothSkin serial number. You are automatically covered by our manufacturer’s warranty. 
                        </p> 
                        <p>
                            此序号为有效的smoothskin慕金序号。您自动享受我们制造商的商品保修。
                        </p>
                        <p>
                            Enjoy your journey to beautiful smooth skin!
                        </p>
                        <p>
                            请享受您的美丽光滑肌肤之旅！
                        </p>
                        <p>&nbsp;</p>
                        <p>
                            <button className='backButton' onClick={() => props.isHidden()}>←&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;GO BACK</button>
                        </p>
                    </div>                   
                </div>  
            </div>
        </div>
    )
}

export default AuthenticationSuccess;